import media from "./mediaPlayer.js";
import portfolioImages from "_texts/components/portfolioImages.js";

const company = "Moben"
const company_link = "https://www.moben.nl"
const mainImage = portfolioImages.moben63

const textProps = {
  items: [
    {
      textSection: [
        { subtitle: "May 2019 - March 2022" },
        { title: "Moben Smart Meter Module" },
      ],
    },
    {
      imagepost: {
        src: mainImage,
        size: "sm",
      },
    },
    {
      textSection: [
        {
          description:
            "Eastground is a company that specializes in building web applications, and they had a great idea for a product of their own. Since they are proficient in making web applications, they wanted to make one where people can see their energy consumption in real time. Since the new P1 meters in the Netherlands provide the ability to read out the current power usage, they figured they needed a device that would log this data to their database. This is why they asked me for help designing their product.",
        },


        { title: "Project Details" },
        {
          description:
            "There are three P1 meter versions spread across the dutch households, and the product should be able to read the current power consumption and log this to a database.",
        },
        {
          description:
            "The product should be able to work off of the supplied power by the P1 meters version 4 and 5, which is +5V/100mA for the weakest version. Since the version 2 meter does not supply any power to connected devices, the product should also include a USB-C charging port.",
        },
        {
          description:
            "The quickest version allows for power consumption data to be refreshed every 1000ms. This means the product should be able to collect this data every 1000ms, and transfer this data to a database. This data transfer will be done over WiFi.",
        },
        {
          description:
            "The device can't miss any data transfer and should be able to run non-stop for multiple years.",
        },


        { title: "My involvement" },
        {
          description:
            "At first I was in charge of all the hardware aspects. Meaning I designed the PCB and the housing for the product. Eastground would program the device themselves. Since the electronics is quite straight forward I was able to complete this is in minimal amount of horus and have a first prototype quickly. After this I made sure they had the prototype available so they could start programming and testing the functionality.",
        },
        {
          description:
            "At this stage I was able to turn their thoughts about the housing into a real design using Fusion 360. The first design was a circular box with a rugged wall-like structure on the sides. Interesting to model, even more interesting to see in real life.",
        },
        {
          description:
            "After a while Eastground decided to up to the ante and ask me to also program the product. Meaning I would create all firmware for power management, WiFi provisioning, data collection, and distribution. The product was based around the ESP32 which gave me the freedom to use their MQTT provision library. As well as using separate cores for the data collection and distribution. Because the product needed to work at low power, < 100mA, and the ESP32 used about 87mA when transmitting data, I needed a way to compensate for the power dips that could happen. This meant I would put the ESP32 into a sleep mode between reads from the meter, allowing for a large capacitor to charge up.",
        },
        {
          description:
            "Sadly, the project was put on hold for about a year when a competitor went to market with exactly the same product.",
        },
        { subtitle: "Final revision" },
        {
          description:
            "Skipping forward to february 2022 when Eastground reached out again telling me they wanted to resume the project with a slight twist. The product needed to be simpler to manufacture and most of all cheaper than it already was.",
        },
        {
          description:
            "I began stripping al non-essential electrical components. Some filtering which was a 'just in case' situation, and changed from a large ESP32 to the miniature variant which was cheaper. We decided that only the version 5 meters wouldn't need assisted power via USB-C which meant the large capacitor could be removed as well.",
        },
        {
          description:
            "They also wanted to re-visit the design and went for a whole different style. I designed a new housing as per their request and it turned out amazing looking! All edges are now smooth and the design got even smaller. The only limiting factor was the RJ11 connector, which is massive in comparison to the rest.",
        },

        { title: "Photo's of the process" },
        {
          description:
            "I made a lot of photo's during this project. For your entertainment I'll share a few of them here. The photo's are in chronological order, providing a small glymp of how the design evolved over time.",
        },
      ],
    },
    {
      mediaplayer: { ...media },
    },
    {
      creditsSection: [
        { 
          title: "Credits",
        },
        {
          description:
            "All photo's of this project belong to " + company + ". I do not claim any rights to these photo's. They will removed or changed if " + company + " requests me to do so. Please visit their website for more information on them and their amazing products.",
        },
        {
          link: company_link,
        },
      ],
    },
  ],
};
export default textProps;
