
const textProps = {
  heading3: {
    color: "light",
    // subtitle: "On the coastline",
    title: "Education and skills",
    description:
      "Get to know more about me and the skills I have mastered over the years on the ",
    descriptionLinkText: "about me page.",
    descriptionLink: "/about",
    alignment: "center",
    whiteContent: true,
  },
  // infoAreas: [
  //   {
  //     ...infoArea1,
  //   },
  //   {
  //     icon: "fas fa-poll",
  //     type: "light",
  //     title: "Grow your market",
  //     description:
  //       "Some quick example text to build on the card title and make up the bulk of the card's content.",
  //   },
  //   {
  //     icon: "fas fa-lightbulb",
  //     type: "light",
  //     title: "Launch time",
  //     description:
  //       "Some quick example text to build on the card title and make up the bulk of the card's content.",
  //   },
  // ],
};
export default textProps;
