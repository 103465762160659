import media from "./mediaPlayer.js";
import portfolioImages from "_texts/components/portfolioImages.js";

const company = "Avutec"
const company_link = "https://avutec.com/"
const mainImage = portfolioImages.avutec76
// const mainImage = portfolioImages.avutec10

const textProps = {
  items: [
    {
      textSection: [
        { subtitle: "February 2018" },
        { title: "Avutec Wiegand Levelshifter" },
      ],
    },
    {
      imagepost: {
        src: mainImage,
        size: "sm",
      },
    },
    {
      textSection: [
        {
          description:
            "Avutec came to me with a request for a small simple design. Their customers would like the option to use the ANPR camera to communicate via Wiegand. This way the customer would be able to control gates via the camera.",
        },


        { title: "Project Details" },
        {
          description:
            "The camera has a small compartment in the back which is closed off with a waterproof lid. The levelshifter had to fit inside this compartment. There is a 6-pin phoenix connector which provides +5V, GND and 4x +3.3V IO directly from the microcontroller. IO 1 and 2 will be used for Wiegand and should be between 0-5V, for which the levelshifter is required. The remaining two IO's need to be used as a switch to control other peripherals. These IO's should be able to switch up to 230Vac.",
        },


        { title: "My involvement" },
        {
          description:
            "I ended up designing a very compact solution including two relays and a levelshifter IC on the bottom. The 4 IO's where now extended to a Wiegand pair, 2 NC and 2 NO contacts. This project wasn't complicated in any way, but since it's the origin story of a few other projects at Avutec so it deserves it's spot on my page.",
        },
        {
          description:
            "At the end of the project I ended up providing a total of 100 levelshifters build by hand. Since not many customers required the levelshifter these 100 pieces lasted almost 2 years. I was working on their new camera's when stock ran out. After which I helped them putting in an order at a PCB vendor.",
        },

        { title: "Photo's of the process" },
        {
          description:
            "I made a lot of photo's during this project. For your entertainment I'll share a few of them here. The photo's are in chronological order, providing a small glymp of how the design evolved over time.",
        },
      ],
    },
    {
      mediaplayer: { ...media },
    },
    {
      creditsSection: [
        { 
          title: "Credits",
        },
        {
          description:
            "All photo's of this project belong to " + company + ". I do not claim any rights to these photo's. They will removed or changed if " + company + " requests me to do so. Please visit their website for more information on them and their amazing products.",
        },
        {
          link: company_link,
        },
      ],
    },
  ],
};
export default textProps;
