const textProps = {
  brand: {
    image: require("assets/images/logo/logoTulpion.png").default,
    link: {
      href: "/dashboard",
    },
  },
  activeColor: "lightBlue",
  items: [
    {
      divider: true,
    },
    {
      title: "Opdrachten",
    },
    {
      icon: "fas fa-hourglass",
      text: "Actief",
      active: true,
      link: {
        href: "/#/opdrachten/actief",
      },
    },
    {
      icon: "fas fa-check",
      text: "Afgerond",
      link: {
        href: "/#/opdrachten/afgerond",
      },
    },
    {
      icon: "fas fa-chart-line",
      text: "Statistieken",
      link: {
        href: "/#/opdrachten/statistieken",
      },
    },
    {
      divider: true,
    },
    {
      title: "Overig",
    },
    {
      icon: "fas fa-map-marked",
      text: "Acquisitie",
      link: {
        href: "/#/overig/acquisitie",
      },
    },
    {
      icon: "fas fa-fingerprint",
      text: "Boekhouding",
      link: {
        href: "/#/overig/boekhouding",
      },
    },
    {
      icon: "fas fa-clipboard-list",
      text: "Page 3 for Section 2",
      link: {
        href: "#pablo",
      },
    },
  ],
};
export default textProps;
