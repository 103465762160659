const textProps = {
  title: "Let's connect!",
  description:
    "Find me on any of these platforms.",
  copyright:
    "Copyright © " +
    new Date().getFullYear() +
    " by Tulpion",
  socials: [
    {
      icon: "linkedin",
      link: "https://www.linkedin.com/in/bas-verheijen-b8956346/",
    },
  ],
  links: [
    {
      name: "Useful Links",
      items: [
        {
          to: "/contact",
          target: "",
          children: "Contact",
        },
        {
          to: "/about",
          target: "",
          children: "About",
        },
        // {
        //   href: "/companies",
        //   target: "",
        //   children: "Companies",
        // },
        // {
        //   to: "/projects",
        //   target: "",
        //   children: "Projects",
        // },
        // {
        //   href: "/calculators",
        //   target: "",
        //   children: "Calculators",
        // },
      ],
    },
    // {
    //   name: "Other Resources",
    //   items: [
    //     // {
    //     //   href: "/license",
    //     //   target: "",
    //     //   children: "License",
    //     // },
    //     // {
    //     //   href: "/terms-conditions",
    //     //   target: "",
    //     //   children: "Terms & Conditions",
    //     // },
    //     // {
    //     //   href: "/privacy",
    //     //   target: "",
    //     //   children: "Privacy Policy",
    //     // },
    //   ],
    // },
  ],
};
export default textProps;
