const textProps = {
  iconColor: "lightBlue",
  title: "Avutec's AI Accelerated 3D camera",
  description:
    "I designed and validated the new range of cameras for Avutec. Their legacy camera, the Gatekeeper, was used for ANPR only. Avutec required a camera which was better equipped to handle big neural networks. This is where I came in and designed the new X-series camera's based on the NVidia Jetson Nano",
  list: [
    { icon: "fas fa-microchip", text: "Multiple PCB Designs" },
    { icon: "fas fa-code", text: "Embedded C++" },
    { icon: "fas fa-wrench", text: "Complete Mechanical Design" },
  ],
};
export default textProps;
