const textProps = [
  {
    icon: "fas fa-microchip",
    iconColor: "red",
    title: "Electronics",
    description:
      "With over 8 years experience designing PCB's and working in many industries I am a great addition to the team. I love to see an idea come to life in my designs!",
  },
  {    
    icon: "fas fa-code",
    iconColor: "emerald",
    title: "Software",
    description:
      "Whether your project requires firmware for your electronics or a user interface, I've got you covered. Over the 6 years of professional experience in C++ and Python combined.",
  },
  {
    icon: "fas fa-wrench",
    iconColor: "lightBlue",
    title: "Mechanical",
    description:
      "Any project requires some form of mechanical design. A lot of the time I come up with creative solutions to a problem. Thinking outside the box is one of my best qualities.",
  },
]
export default textProps;
