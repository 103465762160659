import portfolioImages from "_texts/components/portfolioImages";

const textProps = {
  defaultOpened: 0,
  size: "xs",
  items: [
    portfolioImages.avutec85,
    portfolioImages.avutec36,
    portfolioImages.avutec35,
    portfolioImages.avutec66,
    portfolioImages.avutec61,
    portfolioImages.avutec73,
    portfolioImages.avutec71,
  ],
};
export default textProps;
