import React, {useEffect} from "react";
import { useLocation } from "react-router-dom";
  import ReactGa from 'react-ga'

// import Teams1 from "components/Sections/Presentation/Teams/Teams1.js";
// import ContactUs1 from "components/Sections/Presentation/ContactUs/ContactUs1.js";
// components
// import teams1 from "_texts/presentation/teams/teams1.js";
// import contactus1 from "_texts/presentation/contactus/contactus1.js";
// texts for components as props

// components
import HeaderImageTitleCenter from "components/Headers/Main/HeaderImageTitleCenter.js";
// sections
import Hero1 from "components/Sections/Main/Heroes/Hero1.js";
import Hero2 from "components/Sections/Main/Heroes/Hero2.js";
import Hero3 from "components/Sections/Main/Heroes/Hero3.js";
import FooterLarge from "components/Footers/Landing/FooterLarge.js";

// texts for components as props
import headerimagetitlecenter from "_texts/main/headers/headerimagetitlecenter.js";
// texts for sections as props
import hero1 from "_texts/main/heroes/hero1.js";
import hero2 from "_texts/main/heroes/hero2.js";
import hero3 from "_texts/main/heroes/hero3.js";
import footerlarge from "_texts/presentation/footers/footerlarge.js";

export default function Landing1() {
  const location = useLocation();
  function setGA() {
    ReactGa.initialize('UA-112314905-1')
    ReactGa.pageview('Home')
  }

  useEffect(() => {
      setGA()
  }, [])
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <>
      <main>
        <HeaderImageTitleCenter {...headerimagetitlecenter} />
        <div className="">
          <Hero1 {...hero1} />
        </div>
        <div className="-mt-20">
          <Hero2 {...hero2} />
        </div>
        {/* <Teams1 {...teams1} /> */}
        <div className="-mt-20">
          <Hero3 {...hero3} />
        </div>
        {/* <ContactUs1 {...contactus1} /> */}
      </main>
      <div className="-mt-20">
        <FooterLarge {...footerlarge} />
      </div>
    </>
  );
}
