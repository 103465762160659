import portfolioImages from "_texts/components/portfolioImages";

const textProps = {
  defaultOpened: 0,
  size: "xs",
  items: [
    portfolioImages.cashcryps38,
    portfolioImages.cashcryps1,
    portfolioImages.cashcryps5,
    portfolioImages.cashcryps25,
    portfolioImages.cashcryps26,
    portfolioImages.cashcryps24,
    portfolioImages.cashcryps18,
    portfolioImages.cashcryps34,
  ],
};
export default textProps;
