import React, { useEffect } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";

// import Icon from "@mui/material/Icon";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.css";
import "assets/styles/docs.css";

import NavbarComponents from "_docs/components/NavbarComponents2.js";
import routes from "./routes";

export default function App() {
    const { pathname } = useLocation();
  
    // Setting page scroll to 0 when changing the route
    useEffect(() => {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
    }, [pathname]);
  
    const getRoutes = (allRoutes) =>
      allRoutes.map((route) => {
        if(route.navbar === 'regular'){
            return <Route exact path={route.route} key={route.route}
                    render={() => (
                        <React.Fragment>
                        <NavbarComponents />
                        <div className="pt-17">
                            {route.component}
                        </div>
                        </React.Fragment>
                    )}/>
        }
        else if(route.navbar === 'none'){
            return <Route exact path={route.route} key={route.route}
                    render={() => (
                        <React.Fragment>
                            {route.component}
                        </React.Fragment>
                    )}/>
        }
        else {
            return <Route exact path={route.route} key={route.route}
                    render={() => (
                        <React.Fragment>                            
                            {route.component}
                        </React.Fragment>
                    )}/>
        }
      });
  
    return (
        <Switch>
          {getRoutes(routes)}
          <Redirect path="*" to="/" />
        </Switch>
    );
  }
  
