import infoCards1 from "_texts/main/cards/cardcapabilities";
import infoArea from "_texts/main/infoarea/infoareaicontitlelink.js";
import skewCard from "_texts/main/cards/cardblogimage.js";

const textProps = {
  heading3: {
    color: "lightBlue",
    subtitle: "",
    title: "Hardware and software development in one",
    description:
      "Combining electronic-, mechanical-, and software development makes me the perfect engineer for your creative ideas",
    alignment: "center",
  },
  infoCards: [
      ...infoCards1
  ],
  infoArea: {
    ...infoArea,
  },
  skewCard: {
    ...skewCard,
  },
};
export default textProps;
