// import media from "./mediaPlayer.js";
import portfolioImages from "_texts/components/portfolioImages.js";

const company = "Avutec"
const company_link = "https://avutec.com/"
const mainImage = portfolioImages.avutec84
// const mainImage = portfolioImages.avutec10

const textProps = {
  items: [
    {
      textSection: [
        { subtitle: "April 2019 - September 2021" },
        { title: "Avutec Lens Driver" },
      ],
    },
    {
      imagepost: {
        src: mainImage,
        size: "sm",
      },
    },
    {
      textSection: [
        {
          description:
            "When I was working on the new camera's for Avutec, a couple of problems with the old design became an issue for their customers. Since I was their as their hardware expert, I took responsibility to solve the issue.",
        },


        { title: "Project Details" },
        {
          description:
            "Customers were experiencing faulty lensdrivers in their cameras. From missing steps on the stepper motors, up to completely frozen motors. The current lensdriver was a third-party design and that company wouldn't offer any proper support for this issue.",
        },
        {
          description:
            "The solution required the layout to remain as much intact as possible. Mounting holes couldn't be moved around and connectors should remain somewhat in the same place. The firmware on the third-party drivers wasn't available so the new development required new firmware as well. Freeing up the choice for the microcontroller, which turned out very beneficial when the chip-shortage started.",
        },


        { title: "My involvement" },
        {
          description:
            "I analyzed the root cause of the issue and tried to solve this with the least changes to the original driver as possible. This way we had the most chance to guarantee backwards compatibility and kept it simple.",
        },
        {
          description:
            "At first I redesigned the lensdriver slightly to account for a few voltage drops I noticed when the stepper motor was moving. This solved most of the issues, but never really got to a perfect situation. The way the motorized lenses where build was quite weak and created a lot of sound while moving. Some lenses where better than others. The main cause of the issue was found in the small plastic components used in the lens itself, which the manufacturer wouldn't believe at first. I provided them with detailed test reports and analysis about the difference between their products. We developed a test sequence for the lenses with the new lensdriver. Eventually we got to a point where we could test the lenses when they were delivered. All good ones can be used for production, all bad ones were send back to the manufacturer.",
        },
        {
          description:
            "Because of this issue, we started looking into different manufacturers for the lenses. There where quite a few lenses on the market that turned out much better. I designed electronics to drive these lenses, compatible with the current and new system. Sadly I am unsure whether these better lenses are being used at this moment. This was a deciscion the company would make after my projects ended.",
        },
      ],
    },
    {
      creditsSection: [
        { 
          title: "Credits",
        },
        {
          description:
            "All photo's of this project belong to " + company + ". I do not claim any rights to these photo's. They will removed or changed if " + company + " requests me to do so. Please visit their website for more information on them and their amazing products.",
        },
        {
          link: company_link,
        },
      ],
    },
  ],
};
export default textProps;
