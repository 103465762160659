import profielfoto from 'assets/images/prive/botanis1.jpeg'

const textProps = {
  items: [
    {
      title: "Bas Verheijen",
      description:
        "An experienced Engineer with a demonstrated history of working in the High Tech and Product Development industry. Mainly focussed on the development of electronics and (embedded) software. ",
      image: profielfoto,
      socials: [
        // { icon: "linkedin", link: "https://www.linkedin.com/in/bas-verheijen-b8956346/", target:"_blank" },
      ],
      list: [
        {
          icon: "fas fa-brain",
          text: "Naturally curious",
          color: "pink",
        },
        {
          icon: "fas fa-sitemap",
          text: "Self-organized",
          color: "emerald",
        },
        {
          icon: "fas fa-chart-line",
          text: "Great analytical skills",
          color: "red",
        },
        {
          icon: "fas fa-mug-hot",
          text: "Calm & Patient",
          color: "lightBlue",
        },
      ],
    },
  ],
};
export default textProps;
