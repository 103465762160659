import media from "./mediaPlayer.js";
import portfolioImages from "_texts/components/portfolioImages.js";

const company = "Cash Cryps"
const company_link = ""
const mainImage = portfolioImages.cashcryps38

const textProps = {
  items: [
    {
      textSection: [
        { subtitle: "October 2016 - August 2020" },
        { title: "Cash Cryps Offline Crypto Wallet" },
      ],
    },
    {
      imagepost: {
        src: mainImage,
        size: "sm",
      },
    },
    {
      textSection: [
        {
          description:
            "A customer from Germany requested my help in designing one of his product ideas. Franz had the idea for an offline crypto wallet people would be able to use for payments in stores and to each other. The wallet would fit in peoples pockets, have an E-ink display and some buttons to control it. Using either bluetooth or NFC people would be able to create and confirm payments directly from their wallet. Because of the offline nature of this wallet, no data bundle is required and anyone can use it by simply keeping it around.",
        },


        { title: "Project Details" },
        {
          description:
            "The 'coin' needs to fit inside a persons pocket with ease, be easy to use and be wireless in all sense. Meaning communication with ones phone would be done via bluetooth or NFC, and it can be charged wirelessly.",
        },
        {
          description:
            "The E-ink display, NFC and BLE module were a requirement because the 'coin' should be able to run for several days to weeks without the need for charging. The display will only show through a pentagon hole in the top of the coin, limiting the visible area of the E-ink by a significant amount.",
        },


        { title: "My involvement" },
        {
          description:
            "This project was completed in a few stages. The first stage would be a proof of concept. The second stage would complete the concept with all functions. The last stage was finishing the design. These are quite regular stages for any design process, I recall them as separate because there was about a 10 months break between each stage.",
        },
        { subtitle: "Proof of Concept" },
        {
          description:
            "There was no chargeable battery or charging required yet. Just the main functionalities, being Bluetooth, NFC, and the E-ink display needed to be implemented.",
        },
        {
          description:
            "Creating a small (1 inch diameter) PCB including all parts usually isn't to big of an issue. In this case, the NFC antenna had to be created in the PCB layout as well. This created a big void in the center of the PCB where no parts or traces could be routed. I calculated how small I could go with the antenna design to conserve as much real estate as possible for other components.",
        },
        {
          description:
            "The E-ink was mounted to the top PCB which included the buttons for the user interface. This PCB was then connected to the main PCB via a header connector, creating a PCB sandwich. In the end all function worked well and the software engineer was able to program all communications and visualization.",
        },
        {
          description:
            "The first proof of concept was build and used to show to possible investors for the project.",
        },
        { subtitle: "Complete product" },
        {
          description:
            "About a year later the project continued and I was commisioned to include the battery and charging system within the design. There was only one change to the original design, which was to change the tactile buttons to capacitive touch buttons. This would make for a more flush design, which was preferred.",
        },
        {
          description:
            "Because of the amount of antenna's in such a small space I decided to use the rim of the PCB as a mounting place for the circular antenna which would act as the wireless charging receiver. Based on the QI interface I calculated the antenna specs and added the schematic to the main PCB. Designing and prototyping the antenna proved manageable with the help of some ferrite foil at the right places to improve both the QI and NFC antenna. However, there were quite a few hurdles when I checked the manufacturability of the antenna. Because cost-price was a big factor in the project I had to figure out the cheapest, yet reliable, way to manufacture a custom antenna. Eventually I ended up with a chinese company which only required a few changes in my design for them to manufacture it somewhat affordable.",
        },
        {
          description:
            "At this stage I finalized the design for the coin's housing. Because I had to think of manufacturability I ended up designing a simpel round shell with a closed bottom. The main PCB could be lowered into this and glued to the bottom, securing the QI antenna in it's place. The button PCB would act as the top of the coin. For esthetics, a tape would cover the button PCB giving it an even and flush look.",
        },
        { subtitle: "Production" },
        {
          description:
            "The production stage introduces but before I had the chance to start finalizing al the last changes the project was put on hold until this very day. I would love to finish this project whenever Cash Cryps is ready to proceed into production.",
        },
        

        { title: "Photo's of the process" },
        {
          description:
            "I made a lot of photo's during this project. For your entertainment I'll share a few of them here. The photo's are in chronological order, providing a small glymp of how the design evolved over time.",
        },
      ],
    },
    {
      mediaplayer: { ...media },
    },
    {
      creditsSection: [
        { 
          title: "Credits",
        },
        {
          description:
            "All photo's of this project belong to " + company + ". I do not claim any rights to these photo's. They will removed or changed if " + company + " requests me to do so. Please visit their website for more information on them and their amazing products.",
        },
        {
          link: company_link,
        },
      ],
    },
  ],
};
export default textProps;
