import infoArea from "_texts/main/infoarea/infoareaicontitlelistcolor.js";
import portfolioImages from "_texts/components/portfolioImages";

const textProps = {
  heading2badge: {
    title: "Amazing projects",
    description:
      "I have been lucky enough to have worked on some amazing projects. Like this 3D camera development for Avutec.",
    // descriptionLinkText: "projects page.",
    // descriptionLink: "/projects",
  },
  image: portfolioImages.avutec53,
  infoArea: {
    ...infoArea,
  },
};
export default textProps;
