import React, { useEffect } from "react";
import {render} from "react-dom";
import {  HashRouter } from "react-router-dom";
import App from "./App";

import ReactGa from 'react-ga'

function Main() {

    return (
        <HashRouter>
            <App />
        </HashRouter>
    );
}
export default App;

const appDiv = document.getElementById("root")
render(<Main />, appDiv)

