import media from "./mediaPlayer.js";
import portfolioImages from "_texts/components/portfolioImages.js";

const company = "Sioux"
const company_link = "https://www.sioux.eu/"
const mainImage = portfolioImages.sioux1

const textProps = {
  items: [
    {
      textSection: [
        { subtitle: "June 2017 - December 2018" },
        { title: "Sioux Electron Microscope Upgrade" },
      ],
    },
    {
      imagepost: {
        src: mainImage,
        size: "sm",
      },
    },
    {
      textSection: [
        {
          description:
            "Sioux is a High-Tech engineering bureau involved in absolutely awesome projects. When I was working for Sioux I was assigned to the Phenom World team. Phenom World creates desktop electron microscopes ranging from about 100-500k per unit. Which is quite cheap considering the larger brothers from Phi start at around 1m.",
        },


        { title: "Project Details" },
        {
          description:
            "The current line of SEM's (Scanning Electron Microscopes) are ready for a big upgrade. I was part of the hardware electronics team consisting of 6 engineers at the time.",
        },
        {
          description:
            "I was assigned the upgrade for the Driver Board, which is the heart of the system. The Driver board controls all functions of the machine such as driving the motors to load and unload samples, provide power to other parts in the machine as well as be the center communication hub. The lens for the electron beam was also controlled from this Driver board. It's main purpose is to keep all wheels turning and provide whatever is necessary to the video-board and high-voltage board.",
        },


        { title: "My involvement" },
        {
          description:
            "A small portion of the design was already done by a different engineer before I started at Sioux. I took over the project and quickly realized this was the real kind of high-tech. The specifications were pages upon pages long and all of them had to be ticked off when the design would be released.",
        },
        {
          description:
            "I started with the simple parts like some power conversion and all connections to the microcontroller. After which I proceeded into the more complex parts of the system such as the lens driver. This was essentialy a current source, but the accuracy had to be within 1mA (if I recall correctly). Which meant a lot of filtering and feedbackloops to check the signal.",
        },
        {
          description:
            "Phenom World also wanted the board to be future proof. Which made me decide to implement four edge connector sockets providing all manners of communication and power levels. This way multiple functions could be added at a later stage if required.",
        },
        {
          description:
            "The XL variant of the new SEM's required a more complex stage controller, which was the perfect opportunity to use one of the four slots I just designed. Since the cheaper versions don't need this functionality, and the stage controller is quite expensive, this made it a perfect add-on feature for the Driver Board.",
        },
        {
          description:
            "At the end I delivered a massive Driver Board and a total of two add-ons, one of which was the stage controller and the other a legacy board to solve some issues for the older versions.",
        },
        

        { title: "Photo's of the process" },
        {
          description:
            "I made a lot of photo's during this project. For your entertainment I'll share a few of them here. The photo's are in chronological order, providing a small glymp of how the design evolved over time.",
        },
      ],
    },
    {
      mediaplayer: { ...media },
    },
    {
      creditsSection: [
        { 
          title: "Credits",
        },
        {
          description:
            "All photo's of this project belong to " + company + ". I do not claim any rights to these photo's. They will removed or changed if " + company + " requests me to do so. Please visit their website for more information on them and their amazing products.",
        },
        {
          link: company_link,
        },
      ],
    },
  ],
};
export default textProps;
