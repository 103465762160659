/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// components

export default function ProfDetailArea({
  icon,
  color,
  title,
  items,
  link,
}) {
  const [details, setDetails] = useState([])
  const iconColor = {
    blueGray: "text-blueGray-500 border-blueGray-500",
    red: "text-red-500 border-red-500",
    orange: "text-orange-500 border-orange-500",
    amber: "text-amber-500 border-amber-500",
    emerald: "text-emerald-500 border-emerald-500",
    teal: "text-teal-500 border-teal-500",
    lightBlue: "text-lightBlue-500 border-lightBlue-500",
    indigo: "text-indigo-500 border-indigo-500",
    purple: "text-purple-500 border-purple-500",
    pink: "text-pink-500 border-pink-500",
  };


  function getDetails(){
    let list = []
    for(let i = 0; i < items.length; i++){
      list.push(<p className="text-xl font-semibold leading-tight mb-2 mt-6 justify-center text-center"><b>{items[i].title}</b></p>)
      if(items[i].description !== '')
        list.push(<p className="mb-2 text-blueGray-400 text-center">{items[i].description}</p> )
      let ul = []
      if(items[i].list.length > 0){
        for(let j = 0; j < items[i].list.length; j++)
          ul.push(<li>{items[i].list[j]}</li>)
        list.push(<div className="mb-8 text-blueGray-400"><ul className="">{ul}</ul></div>)
      }
    }
    setDetails(list)
  }
  useEffect(() => { getDetails() })

  return (
    <div className="shadow-lg">
      {icon && (
        <div className="text-center">
          <div
            className={
              iconColor[color] +
              " shadow-lg  justify-center items-center inline-flex text-center mb-2 bg-white px-4 min-w-16 h-16 w-full"
            }
          ><i className={icon + " text-1625"}></i><h6 className="text-xl font-semibold leading-tight ml-2 text-center">{title}</h6>            
          </div>        
          
        </div>
      )}
      {details}
    </div>
  );
}
ProfDetailArea.defaultProps = {
  color: "blueGray",
  link: {},
};
ProfDetailArea.propTypes = {
  // icon name to be added on the <i></i> tag, for example "fa fa-heart"
  icon: PropTypes.string.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  // properties to pass to the link object
  // if you pass a prop named to, the link will be
  // generated using Link from react-router-dom
  link: PropTypes.object,
  color: PropTypes.oneOf([
    "blueGray",
    "red",
    "orange",
    "amber",
    "emerald",
    "teal",
    "lightBlue",
    "indigo",
    "purple",
    "pink",
  ]),
};
