import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import Button from "components/Elements/Button.js";

export default function AboutInfo({ items }) {
 
  return (
    <div className="flex flex-wrap -mx-4">
      <div className="relative">
        <div className="relative w-full overflow-hidden">
          {items.map((prop, key) => {
            return (
              <div
                key={key}
                className={classnames(
                  "relative float-left w-full transform duration-500 transition-all ease-in-out",
                )}
              >
                <div className="container mx-auto px-4">
                  <div className="flex flex-wrap -mx-4">
                    <div className="px-4 relative w-full md:w-6/12 mr-auto flex align-center items-center px-12 ml-auto">
                      <div className="p-4 pt-6 md:p-0">
                        <h2 className="text-2xl font-bold mt-0 mb-2">
                          {prop.title}
                        </h2>
                        <p className="mt-3 text-lg leading-relaxed text-blueGray-500">
                          {prop.description}
                        </p>
                        <ul className="mt-6 list-none">
                          {prop.list &&
                            prop.list.map((listProp, listKey) => {
                              return (
                                <li className="py-2" key={listKey}>
                                  <div className="flex items-center">
                                    <span
                                      className={
                                        "inline-flex text-center rounded-full mr-3 items-center justify-center text-sm w-8 h-8 text-" +
                                        listProp.color +
                                        "-500 bg-" +
                                        listProp.color +
                                        "-200"
                                      }
                                    >
                                      <i className={listProp.icon} />
                                    </span>
                                    <h6 className="text-md text-blueGray-500">
                                      {listProp.text}
                                    </h6>
                                  </div>
                                </li>
                              );
                            })}
                        </ul>
                        <div className="pl-12 text-left">
                          {prop.socials &&
                            prop.socials.map((socialsProp, socialsKey) => {
                              return (
                                <Button
                                  key={socialsKey}
                                  href={socialsProp.link}
                                  color={socialsProp.icon}
                                  target={socialsProp.target}
                                >
                                  <i className={"fab fa-" + socialsProp.icon} />
                                </Button>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                    <div className="px-12 px-4 relative w-full md:w-5/12 ml-auto">
                      <div className="p-6">
                        <img
                          className="max-w-xs rounded-lg shadow-xl transform"
                          src={prop.image}
                          alt="..."
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

AboutInfo.defaultProps = {
  items: [],
};

AboutInfo.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      image: PropTypes.string,
      socials: PropTypes.arrayOf(
        // this will generate an anchor with target blank to the given link
        PropTypes.shape({
          icon: PropTypes.oneOf([
            "facebook",
            "twitter",
            "instagram",
            "dribbble",
            "github",
            "linkedin",
          ]),
          link: PropTypes.string,
        })
      ),
      list: PropTypes.arrayOf(
        PropTypes.shape({
          icon: PropTypes.string,
          text: PropTypes.string,
          color: PropTypes.oneOf([
            "blueGray",
            "red",
            "orange",
            "amber",
            "emerald",
            "teal",
            "lightBlue",
            "indigo",
            "purple",
            "pink",
          ]),
        })
      ),
    })
  ),
};
