
const textProps = {
  heading2: {
    color: "teal",
    title: "Skills & Education",
    description:
      "A complete overview of my skills and education. Hobbies and knowledge gained from personal projects are excluded from this list.",
    alignment: "center",
    whiteContent: true,
  },
  cards: [
    {
      icon: "fas fa-language",
      color: "emerald",
      title: "Languages",
      description:
        "The mentioned languages are only the ones I feel comfortable mentioning. I have some basic skills in a few other languages, but none I would call professional applicable.",
      list: [
        {
          icon: "fas  fa-globe",
          text: "Dutch (native)",
          color: "pink",
        },
        {
          icon: "fas fa-globe",
          text: "English (fluent)",
          color: "emerald",
        },
      ],
    },
    {
      icon: "fas fa-graduation-cap",
      color: "red",
      title: "Education",
      description:
        "My education profile is longer than this list, but these are the most comprehensives degrees and courses I have completed.",
      list: [
        {
          icon: "fas  fa-graduation-cap",
          text: "BSc. Electrical Engineering (2016)",
          color: "pink",
        },
        {
          icon: "fas fa-book-reader",
          text: "Django Course by Code with Mosh (2022)",
          color: "emerald",
        },
        {
          icon: "fas fa-book-reader",
          text: "Altium Advanced Course (2018)",
          color: "red",
        },
        {
          icon: "fas fa-book-reader",
          text: "Creative Thinking by Mark Vandael (2018)",
          color: "lightBlue",
        },
        {
          icon: "fas fa-book-reader",
          text: "Acradac EMC Training by Lex de Rijck (2016)",
          color: "orange",
        },
        {
          icon: "fas fa-book-reader",
          text: "SolidWorks Associate CSWA (2016)",
          color: "teal",
        },
      ],
    },
    {
      icon: "fas fa-code",
      color: "blueGray",
      title: "Programming Languages",
      description:
        "There are a lot of programming languages to master. The languages are ordered from most to least used.",
      list: [
        {
          icon: "fas  fa-code",
          text: "C++",
          color: "pink",
        },
        {
          icon: "fas fa-code",
          text: "Python",
          color: "emerald",
        },
        {
          icon: "fas fa-code",
          text: "C",
          color: "red",
        },
        {
          icon: "fas fa-code",
          text: "Javascript",
          color: "lightBlue",
        },
        {
          icon: "fas fa-code",
          text: "HTML / PHP / CSS",
          color: "orange",
        },
        {
          icon: "fas fa-code",
          text: "Dart",
          color: "teal",
        },
      ],
    },
    {
      icon: "fas fa-laptop-code",
      color: "orange",
      title: "Libraries & Frameworks",
      description:
        "The mentioned libraries and frameworks are only the ones I use or have used on a regular basis. In my spare time I tend to use ones I don't yet know to broaden my knowledge. There is no specific order for this list.",
      list: [
        {
          icon: "fas  fa-table",
          text: "Arduino",
          color: "pink",
        },
        {
          icon: "fas fa-table",
          text: "Django",
          color: "emerald",
        },
        {
          icon: "fas fa-table",
          text: "Flutter",
          color: "red",
        },
        {
          icon: "fas fa-table",
          text: "TEST-OK",
          color: "lightBlue",
        },
        {
          icon: "fas fa-table",
          text: "Tensorflow",
          color: "orange",
        },
        {
          icon: "fas fa-table",
          text: "Docker",
          color: "teal",
        },
        {
          icon: "fas fa-table",
          text: "Git",
          color: "pink",
        },
        {
          icon: "fas fa-book",
          text: "React JS",
          color: "emerald",
        },
      ],
    },
    {
      icon: "fas fa-laptop",
      color: "teal",
      title: "Programs & Tools",
      description:
        "The listed programs and tools are tools I use or have used on a regular basis. They're ordered in frequency of usage, compared within their respective field.",
      list: [
        {
          icon: "fas  fa-microchip",
          text: "Altium Designer",
          color: "pink",
        },
        {
          icon: "fas fa-bug",
          text: "Visual Studio",
          color: "emerald",
        },
        {
          icon: "fas fa-bug",
          text: "VS Code",
          color: "red",
        },
        {
          icon: "fas fa-bug",
          text: "Arduino IDE",
          color: "lightBlue",
        },
        {
          icon: "fas fa-bug",
          text: "Eclipse",
          color: "orange",
        },
        {
          icon: "fas fa-bug",
          text: "Microchip Studio",
          color: "teal",
        },
        {
          icon: "fas  fa-wrench",
          text: "Fusion 360",
          color: "pink",
        },
        {
          icon: "fas fa-wrench",
          text: "Solid Works",
          color: "emerald",
        },
        {
          icon: "fas fa-wrench",
          text: "Solid Edge",
          color: "red",
        },
      ],
    },
  ],
};
export default textProps;
