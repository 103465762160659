import portfolioImages from "_texts/components/portfolioImages";

const textProps = {
  defaultOpened: 0,
  size: "xs",
  items: [
    portfolioImages.moben11,
    portfolioImages.moben31,
    portfolioImages.moben38,
    portfolioImages.moben51,
    portfolioImages.moben63,
    portfolioImages.moben64,
    portfolioImages.moben65,
    portfolioImages.moben66,
  ],
};
export default textProps;
