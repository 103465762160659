import card from "_texts/presentation/cards/cardcontactusicons.js";

const textProps = {
  heading2badge: {
    title: "Get in touch!",
    description:
      "Use below form to leave me a message. I usually reply within one business day.",
    alignment: "center",
  },
  card: {
    ...card,
  },
  skew: {
    color: "light",
  },
  infoAreas: [
    {
      icon: "fas fa-map-marker-alt",
      color: "lightBlue",
      title: "Address",
      description: "Aresstraat 13-06, 5048CD Tilburg",
    },
    {
      icon: "fas fa-envelope",
      color: "lightBlue",
      title: "Email",
      description: "bverheijen@tulpion.com",
    },
    {
      icon: "fas fa-mobile-alt",
      color: "lightBlue",
      title: "Phone",
      description: "+31 13 2400080",
    },
    {
      icon: "fab fa-whatsapp",
      color: "lightBlue",
      title: "Whatsapp",
      description: "+31 13 2400080",
    },
  ]
};
export default textProps;
