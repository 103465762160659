import React from "react";
import PropTypes from "prop-types";

// components

import AboutInfo from "./aboutInfo.js";

export default function About({ info }) {
  return (
    <>
      <div className="">
        <AboutInfo {...info} />
      </div>
    </>
  );
}

About.defaultProps = {
  info: {},
};
About.propTypes = {
  info: PropTypes.object,
};
