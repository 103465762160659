import React, {useEffect} from "react";
import { useLocation } from "react-router-dom";
  import ReactGa from 'react-ga'

// sections
import Projects4 from "components/Sections/Projects/Projects/Projects4";
// components
import FooterLarge from "components/Footers/Landing/FooterLarge";
// texts for components as props
import footerlarge from "_texts/presentation/footers/footerlarge.js"

import project from "_texts/projects/articles/avutec-lensdriver/avutecLensdriver.js";

export default function AvutecLensdriver() {
  const location = useLocation();
  function setGA() {
    ReactGa.initialize('UA-112314905-1')
    ReactGa.pageview('Avutec Lensdriver')
  }

  useEffect(() => {
      setGA()
  }, [])
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <>
      <Projects4 {...project} />
      <FooterLarge {...footerlarge} />
    </>
  );
}
