import mediaGatekeeperX from "./mediaPlayerGatekeeperX.js";
import mediaModuleX from "./mediaPlayerModuleX.js";
import portfolioImages from "_texts/components/portfolioImages.js";

const company = "Avutec"
const company_link = "https://avutec.com/"
const mainImage = portfolioImages.avutec95

const textProps = {
  items: [
    {
      textSection: [
        { subtitle: "April 2019 - September 2021" },
        { title: "Avutec 3D Camera" },
      ],
    },
    {
      imagepost: {
        src: mainImage,
        size: "lg",
      },
    },
    {
      textSection: [
        {
          description:
            "While I was traveling through Thailand Avutec called me with a question. Their current ANPR camera displayed some strange interferences and they couldn't find what was causing it. During a quick video call we defined a few tests they could do. We identified the cause of the problem quickly and defined some possible solutions.",
        },
        {
          description:
            "After the issue was solved we got talking about the camera and their plans to develop a whole new camera with way more capabilities than their current one. They asked me to let them know when I returned to the Netherlands because they'd like to discuss my possible involvement for the development. And so I did. I returned on om March 26th 2019 and started with the project om April 1st.",
        },
        { title: "Project Details" },
        {
          description:
            "This project was a combination of two designs. The first design was an upgrade of their current camera, the Gatekeeper. This camera was build around an Odroid-XU4 processing unit and contained a lensdriver to drive the motorized optical zoom lens. The second design was a complete new idea. This camera started out a smaller version of the Gatekeeper with a stationary zoom lens. In the end this camera would turn into two versions, one with a zoom lens and one with two identical image sensors for 3D purposes.",
        },
        {
          description:
            "Both camera's would be build on a new and more AI dedicated processor. The choice was between the Nvidia Jetson Nano and the Google Coral, both had sufficient specifications. We went with the Nvidia because their support and history of continuity was a lot better than that of Google.",
        },
        {
          description:
            "The upgraded camera, Gatekeeper X, had to include at least all capabilities of the current version. All peripherals to the outside world could be improved upon but the functionality had to remain the same. Inside, the camera would change drastically though. The carrierboard would have to be powered by POE+, include multiple status sensors, double MIPI connections for image sensors, power the high power infra red LED's and be able to control multiple different motorized zoom lenses.",
        },
        {
          description:
            "The new camera, Module X IR and Module X 3D, had nearly the same requirements as the Gatekeeper X. The main differencer are the exterior design, a RS485 connection, could be powered by POE+ as well as by a 12-48V power source, and have multiple outputs and inputs to control external systems. The main idea was for the camera to be build into lamp posts and other structures in an easy drop-in way.",
        },
        { title: "My involvement" },
        {
          description:
            "For this project I would be in charge of all the hardware development. Meaning I designed all electronics from scratch and created the design for the new camera. Using Altium Designer for the PCB design and Fusion 360 for the mechanic design I managed to create a beautiful camera with a modern feel and a lot of processing power for Neural Networks and 3D applications.",
        },
        {
          description:
            "The PCBs all included at least one microcontroller for which I wrote the first versions of the firmware. The firmware was written in C++ as well as all the drivers for the sensors. I used a layered Master-Slave architecture, where the Jetson Nano was the master over the main controller. The main controller acts as the master to all smaller PCB's and sensors.",
        },
        { title: "Photo's of the process" },
        {
          description:
            "I made a lot of photo's during this project. For your entertainment I'll share a few of them here. The photo's are in chronological order, providing a small glymp of how the design evolved over time.",
        },
        {
          description:
            "The photo's below are from the upgrading process for the Gatekeeper X",
        },
      ],
    },
    {
      mediaplayer: { ...mediaGatekeeperX },
    },
    {
      textSection: [
        {
          description:
            "The photo's below are from the upgrading process for the Module X IR and Module X 3D",
        },
      ],
    },
    {
      mediaplayer: { ...mediaModuleX },
    },
    {
      creditsSection: [
        { 
          title: "Credits",
        },
        {
          description:
            "All photo's of this project belong to " + company + ". I do not claim any rights to these photo's. They will removed or changed if " + company + " requests me to do so. Please visit their website for more information on them and their amazing products.",
        },
        {
          link: company_link,
        },
      ],
    },
  ],
};
export default textProps;
