import React from "react";
import { Link } from "react-router-dom";

import DropdownHoverNavbar from "_docs/components/DropdownHoverCSSNavbar.js";

import global from "assets/globals";
import projects from "_texts/projects/navbarData";

export default function NavbarComponents() {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  return (
    <>
      <nav className="fixed w-full bg-blueGray-900 flex flex-wrap items-center justify-between px-2 py-3 z-9999">
        <div className="w-full px-4 mx-auto flex flex-wrap items-center justify-between max-h-screen-70 overflow-y-auto">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            <Link
              to="/"
              className="text-white text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase "
            >
              <img src={global.brandLogo} className="docs-components-logo" alt="Tulpion"/>
            </Link>
          </div>
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start mr-4">
            <Link
              to="/"
              className="text-white text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase"
            >
              {global.brandName}
            </Link>
            <button
              className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <i className="text-white fas fa-bars"></i>
            </button>
          </div>
          <div
            className={
              "lg:flex flex-grow items-center bg-white lg:bg-transparent lg:shadow-none" +
              (navbarOpen ? " block rounded lg:shadow-lg" : " hidden")
            }
          >
            <ul className="flex flex-col lg:flex-row list-none mr-auto lg:items-center">
              <li>
                <Link
                  to="/about"
                  className="hover:opacity-75 lg:text-white px-5 py-4 lg:py-2 flex items-center text-xs uppercase font-bold transition-all duration-150 ease-in-out text-blueGray-800"
                >
                  About
                </Link>
              </li>
              {/* <li>
                <DropdownNavbar
                  text="Components"
                  navColor="dark"
                  items={routes.map((prop, key) => {
                    return {
                      key: key,
                      to: prop.path || "/components#" + prop.id,
                      children: prop.title,
                    };
                  })}
                />
              </li> */}
              {/* <li>
                <DropdownHoverNavbar
                  text="Companies"
                  navColor="dark"
                  items={[
                    {
                      link: '/companies',
                      title: "Companies",
                      items: [
                        { to: "/ame", children: "Applied Micro Electronics (AME)" },
                        { to: "/avutec", children: "Avutec" },
                        { to: "/cash-cryps", children: "Cash Cryps" },
                        { to: "/leasair", children: "Leasair" },
                        { to: "/moben", children: "Moben" },
                        { to: "/nottrot", children: "Nottrot" },
                        { to: "/nexi-industries", children: "Nexi Industries" },
                        { to: "/sioux", children: "Sioux Technologies" },
                      ],
                    },
                  ]}
                />
              </li> */}
              <li>
                <DropdownHoverNavbar
                  text="Projects"
                  navColor="dark"
                  items={[
                    {
                      to: '/',
                      title: "Projects",
                      items: projects,
                    },
                  ]}
                />
              </li>
            </ul>
            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto lg:items-center">              
              <li>
                <Link
                  to="/contact"
                  className="text-xs px-4 py-2 shadow hover:shadow-md text-blueGray-800 bg-blueGray-100 border-blueGray-100 active:bg-blueGray-300 active:border-blueGray-300 rounded outline-none focus:outline-none align-middle transition-all duration-150 ease-in-out uppercase border border-solid font-bold"
                >
                  Get in contact
                </Link>
              </li>
              {/* <li>
                <a
                  href="/contact-me"
                  className="text-xs px-4 py-2 shadow hover:shadow-md text-white bg-blueGray-800 border-blueGray-800 active:bg-blueGray-900 active:border-blueGray-900 rounded outline-none focus:outline-none align-middle transition-all duration-150 ease-in-out uppercase border border-solid font-bold mr-2"
                >
                  Get in contact
                </a>
              </li> */}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
