import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

// components
import Heading2Badge from "../../../../components/Headings/Heading2Badge.js";
import CardContactUsIcons from "../../../../components/Cards/Presentation/CardContactUsIcons.js";
import InfoAreaIcon from "../../../../components/InfoAreas/Presentation/InfoAreaIcon.js";

export default function ContactUs3({ heading2badge, card, infoAreas, map, skew }) {
  return (
    <>
      <div className="">
        <div className="w-full px-16 mx-auto px-4 mt-12 mb-6">
          {heading2badge && <Heading2Badge {...heading2badge} />}
        </div>
        <div className="flex flex-wrap -mx-4 mb-6">
          <div className="mx-auto px-4 relative w-full md:w-6/12">
            <CardContactUsIcons {...card} />
          </div>
        </div>
        <div className="flex flex-wrap -mx-4 mt-12 mb-16 ">
            {infoAreas.map((prop, key) => {
              return (
                <div
                  key={key}
                  className={classnames(
                    "px-4 relative w-full lg:w-3/12 w-full md:w-6/12 w-6/12",
                    { "border-r": key !== infoAreas.length - 1 }
                  )}
                >
                  <InfoAreaIcon {...prop} />
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
}
ContactUs3.defaultProps = {
  heading2badge: null,
  infoAreas: [],
  map: {},
};
ContactUs3.propTypes = {
  // prop to pass to the Heading2Badge component
  heading2badge: PropTypes.object,
  // it is represented by the props
  // that can be passed to the Button,
  // so please check that one out
  badge: PropTypes.object,
  // it is represented by the props
  // that can be passed to the CardContactUsIcons,
  // so please check that one out
  card: PropTypes.object,
  // it is represented by the props
  // that can be passed to the Map,
  // so please check that one out
  map: PropTypes.object,
  // it is represented by the props
  // that can be passed to the Skew,
  // so please check that one out
  skew: PropTypes.object,
};
