import portfolioImages from "_texts/components/portfolioImages";

const textProps = {
  defaultOpened: 0,
  size: "xs",
  items: [
    portfolioImages.sioux4,
    portfolioImages.sioux6,
    portfolioImages.sioux12,
    portfolioImages.sioux17,
    portfolioImages.sioux9,
    portfolioImages.sioux11,
    portfolioImages.sioux18,
    portfolioImages.sioux15,
    portfolioImages.sioux38,
  ],
};
export default textProps;
