
const projects = [
    { to: "/avutec-camera", children: "3D Camera" },
    { to: "/sioux-phenom", children: "Electron Microscope Upgrade" },
    { to: "/cashcryps-wallet", children: "Offline Crypto Wallet" },
    { to: "/moben-meter", children: "P1 Meter" },
    { to: "/sioux-testok", children: "Test-OK Setup" },
    // { to: "/nexi-pedalboard", children: "Pedalboard" },
    // { to: "/mpcnc", children: "MPCNC" },
    { to: "/avutec-lensdriver", children: "Cameralens Driver" },
    { to: "/avutec-wiegand", children: "Wiegand Levelshifter" },
]
export default projects