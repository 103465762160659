import React from "react";

// Docs Routes - START - you can delete these when you no longer need our docs
// import Components from "_docs/layouts/Components.js";
// import Documentation from "_docs/layouts/Documentation.js";
// // Docs Routes -  STOP

// // Product Pages - START
// // admin
import Dashboard from "views/admin/Dashboard.js";
// import Maps from "views/admin/Maps.js";
// import Settings2 from "views/admin/Settings2.js";
// import Tables from "views/admin/Tables.js";
// // // auth
// import Login1 from "views/auth/Login1.js";
// import Login2 from "views/auth/Login2.js";
// import Register1 from "views/auth/Register1.js";
// import Register2 from "views/auth/Register2.js";
// import Reset from "views/auth/Reset.js";
// // // blog
// import BlogPost from "views/blog/BlogPost.js";
// import BlogPosts from "views/blog/BlogPosts.js";
// // // e-commerce
// import Chat from "views/e-commerce/Chat.js";
// import Checkout from "views/e-commerce/Checkout.js";
// import ECommerce from "views/e-commerce/ECommerce.js";
// import Invoice from "views/e-commerce/Invoice.js";
// import Pricing from "views/e-commerce/Pricing.js";
// import Product from "views/e-commerce/Product.js";
// import Profile1 from "views/e-commerce/Profile1.js";
// import Settings1 from "views/e-commerce/Settings1.js";
// // // error
// import Error404 from "views/error/Error404.js";
// import Error500 from "views/error/Error500.js";
// // // presentation
// import Landing1 from "views/presentation/Landing1.js";
// import Landing2 from "views/presentation/Landing2.js";
import AboutUs from "views/presentation/AboutUs.js";
import ContactUs from "views/presentation/ContactUs.js";
// Product Pages - STOP
import Home from "views/main/Home.js"
// Project Pages
// import ProjectList from "views/projects/ProjectList";
import AvutecCamera from "views/projects/articles/AvutecCamera";
import AvutecLensdriver from "views/projects/articles/AvutecLensdriver";
import AvutecWiegand from "views/projects/articles/AvutecWiegand";
import CashCrypsCoin from "views/projects/articles/CashCrypsCoin";
import MobenMeter from "views/projects/articles/MobenMeter";
import MpCNC from "views/projects/articles/MpCNC";
import NexiPedalboard from "views/projects/articles/NexiPedalboard";
import SiouxPhenom from "views/projects/articles/SiouxPhenom";
import SiouxTestOk from "views/projects/articles/SiouxTestOk";



const routes = [
  // Login Pages
  {
    navbar: "regular",
    route: "/",
    component: <Home />,
  },
  {
    navbar: "regular",
    route: "/contact",
    component: <ContactUs />,
  },
  {
    navbar: "regular",
    route: "/about",
    component: <AboutUs />,
  },
  // {
  //   navbar: "regular",
  //   route: "/projects",
  //   component: <ProjectList />,
  // },
  {
    navbar: "regular",
    route: "/avutec-camera",
    component: <AvutecCamera />,
  },
  {
    navbar: "regular",
    route: "/avutec-lensdriver",
    component: <AvutecLensdriver />,
  },
  {
    navbar: "regular",
    route: "/avutec-wiegand",
    component: <AvutecWiegand />,
  },
  {
    navbar: "regular",
    route: "/cashcryps-wallet",
    component: <CashCrypsCoin />,
  },
  {
    navbar: "regular",
    route: "/moben-meter",
    component: <MobenMeter />,
  },
  {
    navbar: "regular",
    route: "/mpcnc",
    component: <MpCNC />,
  },
  {
    navbar: "regular",
    route: "/nexi-pedalboard",
    component: <NexiPedalboard />,
  },
  {
    navbar: "regular",
    route: "/sioux-phenom",
    component: <SiouxPhenom />,
  },
  {
    navbar: "regular",
    route: "/sioux-testok",
    component: <SiouxTestOk />,
  },

  

  // {
  //   navbar: "none",
  //   route: "/dashboard",
  //   component: <Dashboard />,
  // },





  // {
  //   navbar: "regular",
  //   route: "/regular",
  //   component: <Landing1 />,
  // },
  // {
  //   navbar: "regular",
  //   route: "/landing-2",
  //   component: <Landing2 />,
  // },
  // {
  //   navbar: "regular",
  //   route: "/contact-us",
  //   component: <ContactUs />,
  // },
  // {
  //   navbar: "regular",
  //   route: "/dashboard",
  //   component: <Dashboard />,
  // },
  // {
  //   navbar: "regular",
  //   route: "/maps",
  //   component: <Maps />,
  // },
  // {
  //   navbar: "regular",
  //   route: "/settings-2",
  //   component: <Settings2 />,
  // },
  // {
  //   navbar: "regular",
  //   route: "/tables",
  //   component: <Tables />,
  // },
  // {
  //   navbar: "regular",
  //   route: "/login-1",
  //   component: <Login1 />,
  // },
  // {
  //   navbar: "regular",
  //   route: "/login-2",
  //   component: <Login2 />,
  // },
  // {
  //   navbar: "regular",
  //   route: "/register-1",
  //   component: <Register1 />,
  // },
  // {
  //   navbar: "regular",
  //   route: "/register-2",
  //   component: <Register2 />,
  // },
  // {
  //   navbar: "regular",
  //   route: "/reset",
  //   component: <Reset />,
  // },
  // {
  //   navbar: "regular",
  //   route: "/blog-post",
  //   component: <BlogPost />,
  // },
  // {
  //   navbar: "regular",
  //   route: "/blog-posts",
  //   component: <BlogPosts />,
  // },
  // {
  //   navbar: "regular",
  //   route: "/chat",
  //   component: <Chat />,
  // },
  // {
  //   navbar: "regular",
  //   route: "/checkout",
  //   component: <Checkout />,
  // },
  // {
  //   navbar: "regular",
  //   route: "/e-commerce",
  //   component: <ECommerce />,
  // },
  // {
  //   navbar: "regular",
  //   route: "/invoice",
  //   component: <Invoice />,
  // },
  // {
  //   navbar: "regular",
  //   route: "/pricing",
  //   component: <Pricing />,
  // },
  // {
  //   navbar: "regular",
  //   route: "/product",
  //   component: <Product />,
  // },
  // {
  //   navbar: "regular",
  //   route: "/profile-1",
  //   component: <Profile1 />,
  // },
  // {
  //   navbar: "regular",
  //   route: "/settings-1",
  //   component: <Settings1 />,
  // },
  // {
  //   navbar: "none",
  //   route: "/error-404",
  //   component: <Error404 />,
  // },
  // {
  //   navbar: "regular",
  //   route: "/error-500",
  //   component: <Error500 />,
  // },
];
export default routes;
