import media from "./mediaPlayer.js";
import portfolioImages from "_texts/components/portfolioImages.js";

const company = "Sioux"
const company_link = "https://www.sioux.eu/"
const mainImage = portfolioImages.sioux29

const textProps = {
  items: [
    {
      textSection: [
        { subtitle: "June 2017 - December 2018" },
        { title: "Moben Smart Meter Module" },
      ],
    },
    {
      imagepost: {
        src: mainImage,
        size: "sm",
      },
    },
    {
      textSection: [
        {
          description:
            "The Driver Board and it's add-ons required testing after production to make sure Phenom World didn't have any issues when they added them during their production.",
        },


        { title: "Project Details" },
        {
          description:
            "The Driver Board and XL Shield (stage controller) need to be quickly tested on all features without having to build them in or use any of the connectors.",
        },


        { title: "My involvement" },
        {
          description:
            "Sioux had decided they wanted to start working with Test-OK in their production branch. So I designed a Test-OK setup for both the Driver Board and the XL Board. All parts can be designed in Altium Designer as all of the plates are made from PCB material. Not all of them include any circuitry though. Some only have a mechanical function in the system, but require cut-outs for the pogo-pins to be guided through.",
        },
        {
          description:
            "Test-OK is a combination of a physical testing rack in which the DUT (device under test) can be placed, and a scripting environment to automate all tests. The concept is simple, a DUT is placed on the dedicated testing rack and as the lid is closed the spring loaded pins move toward the DUT, which has exposed pads or through hole connections to which the pins connect. As soon as the lid is fully closed, the program will run all tests and the results are printed on screen.",
        },
        {
          description:
            "To build a setup I had to first design the testing rack and all measuring electronics which could than feed back information to the system about voltage levels, IO status' etc. Than I created a script for every test I wanted to be executed when testing a DUT. Meaning one script for every main function. Multiple levels can be checked in one script. For example, I made one script which I called 'main power'. This script measured all voltage levels on the board which were created when the main power was supplied.",
        },
        {
          description:
            "In the end I delivered two working test setups which could test all functions on both the Driver Board and XL Shield within seconds.",
        },

        { title: "Photo's of the process" },
        {
          description:
            "I made a lot of photo's during this project. For your entertainment I'll share a few of them here. The photo's are in chronological order, providing a small glymp of how the design evolved over time.",
        },
      ],
    },
    {
      mediaplayer: { ...media },
    },
    {
      creditsSection: [
        { 
          title: "Credits",
        },
        {
          description:
            "All photo's of this project belong to " + company + ". I do not claim any rights to these photo's. They will removed or changed if " + company + " requests me to do so. Please visit their website for more information on them and their amazing products.",
        },
        {
          link: company_link,
        },
      ],
    },
  ],
};
export default textProps;
