
import header1 from 'assets/images/photoshop/header1.png'
import header2 from 'assets/images/photoshop/header2.png'
import companies1 from 'assets/images/photoshop/companies1.png'
import companies2 from 'assets/images/photoshop/companies2.png'

const photoshopImages = {
        'header1': header1,
        'header2': header2,
        'companies1': companies1,
        'companies2': companies2,
}

export default photoshopImages
