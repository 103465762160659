import portfolioImages from "_texts/components/portfolioImages";

const textProps = {
  defaultOpened: 0,
  size: "xs",
  items: [
    portfolioImages.sioux26,
    portfolioImages.sioux24,
    portfolioImages.sioux27,
    portfolioImages.sioux30,
    portfolioImages.sioux33,
  ],
};
export default textProps;
