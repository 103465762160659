import portfolioImages from "_texts/components/portfolioImages";

const textProps = {
  defaultOpened: 0,
  size: "xs",
  items: [
    portfolioImages.avutec70,
    portfolioImages.avutec75,
  ],
};
export default textProps;
