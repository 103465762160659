import React, {useEffect} from "react";
import { useLocation } from "react-router-dom";
  import ReactGa from 'react-ga'

// sections
import ContactUs3 from "components/Sections/Presentation/ContactUs/ContactUs3.js";
// components
// texts for components as props
import contactus3 from "_texts/presentation/contactus/contactus3.js";

import FooterLarge from "components/Footers/Landing/FooterLarge.js";
import footerlarge from "_texts/presentation/footers/footerlarge.js";

export default function ContactUs() {
  const location = useLocation();
  function setGA() {
    ReactGa.initialize('UA-112314905-1')
    ReactGa.pageview('Contact')
  }

  useEffect(() => {
      setGA()
  }, [])
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <>
      <div>
        <ContactUs3 {...contactus3} />
        <div className="-mt-20">
          <FooterLarge {...footerlarge} />
        </div>
      </div>
    </>
  );
}
