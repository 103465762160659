import photoshopImages from "_texts/components/photoshopImages";

const textProps = {
  image: photoshopImages.companies2,
  title: "",
  description:
    "",
  color: "lightBlue",
};
export default textProps;
