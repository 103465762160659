import photoshopImages from "_texts/components/photoshopImages";


const textProps = {
  bgImage: photoshopImages.header2,
  // title: "Bas Verheijen",
  // description:
  //   'Freelance Hardware | Software engineer',  
};
export default textProps;
