const textProps = {
  copyright:
    "Copyright © " +
    new Date().getFullYear() +
    " by Tulpion",
  links: [
    {
      children: "Home",
      href: "/",
      target: "",
    },
    {
      children: "About me",
      href: "/about",
      target: "",
    },
    {
      children: "Experience",
      href: "/experience",
      target: "",
    },
    {
      children: "Projects",
      href: "/projects",
      target: "",
    },
    {
      children: "Contact me",
      href: "/contact",
      target: "",
    },
  ],
};
export default textProps;
