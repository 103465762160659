import portfolioImages from "_texts/components/portfolioImages";

const textProps = {
  defaultOpened: 0,
  size: "xs",
  items: [
    portfolioImages.avutec42,
    portfolioImages.avutec45,
    portfolioImages.avutec46,
    portfolioImages.avutec3,
    portfolioImages.avutec2,
    portfolioImages.avutec6,
    portfolioImages.avutec1,
    portfolioImages.avutec53,
    portfolioImages.avutec56,
    portfolioImages.avutec69,
    portfolioImages.avutec68,
    portfolioImages.avutec12,
    portfolioImages.avutec11,
    portfolioImages.avutec96,
    portfolioImages.avutec97,
    portfolioImages.avutec99,
    portfolioImages.avutec101,
  ],
};
export default textProps;
