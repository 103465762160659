const textProps = {
  title: "Long-term vision and a team player",
  description: [
    "Any project requires an engineer who can keep an eye out for the long-term results. I like to look at the big picture and define where I want to end up. After which I will break the project up into many smaller review moments to track the progress. This helps me achieve the best results, and provides space to adjust during the development process.",
    "Working in a team provides the feedback we all need. No one is perfect, so helping each other filter out those small oversights can help loads! I have worked with great teams at small and large companies, as well as solo quite a few solo projects. Whatever the environment, I easily adapt to the workflow required.",
  ],
  // link: {
  //   text: "Check out all the companies I worked for",
  //   link: "/companies",
  //   innerLink: true,
  // },
};
export default textProps;
